@font-face {
    font-family: 'Mattone-Regular';
    font-weight: normal;
    src: url('./fonts/Mattone-Regular.otf');
}

@font-face {
    font-family: 'Mena-Grotesk';
    font-weight: normal;
    src: url('./fonts/Mena\ Grotesk\ Book.otf');
}

@font-face {
    font-family: 'Mena-Grotesk';
    font-weight: bold;
    src: url('./fonts/Mena\ Grotesk\ Medium.otf');
}